:root {
  --brand-color: #e1c2a3;
  --brand-light-color: #fff3e7;
  --brand-font-family: "Inria Serif", serif;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

.text-brand {
  color: var(--brand-color);
}

.bg-brand {
  background-color: var(--brand-color);
}

.bg-brand-light {
  background-color: var(--brand-light-color);
}

.ff-brand {
  font-family: var(--brand-font-family);
}

.noUi-connect {
  background-color: var(--brand-color) !important;
}