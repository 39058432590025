/* Hover functionality for dropdown */
.nav-item.dropdown:hover .dropdown-menu {
    display: block;
}

.nav-item.dropdown:hover .nav-link {
    color: #007bff;
}

/* To avoid flickering issues */
.nav-item.dropdown .dropdown-menu {
    margin-top: 0;
}