.product-card {
    width: 294px;
    transition: 0.5s;
}

.product-card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    cursor: pointer;
}

.product-card-img {
    height: 400px;
    object-fit: cover;
    transition: 1s;
    animation: glow 0.4s linear;
}

.product-discount-badge {
    top: 20px;
    left: 10px;
    font-size: 10px;
}

.product-select-option {
    width: 100%;
    display: none;
    justify-content: center;
    bottom: 105px;
}

.product-card:hover .product-select-option {
    display: flex;
    animation: glow 0.4s linear;
}

@media screen and (max-width: 1400px) {
    .product-card {
        width: 248px;
    }
}

@media screen and (max-width: 1200px) {
    .product-card {
        width: 204px;
    }
}

@media screen and (max-width: 768px) {
    .product-card {
        width: 238px;
    }
}

@media screen and (max-width: 576px) {
    .product-card {
        width: 100%;
    }

    .product-card-img {
        height: auto;
    }
}

@keyframes glow {
    0% {
        opacity: 0%;
    }
    100% {
        opacity: 100%;
    }
}