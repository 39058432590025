.pvm-img-select img {
  width: 33px;
  height: 33px;
  object-fit: cover;
}

.pvm-desc {
  overflow: hidden;
  display: -webkit-box;
  line-clamp: 2;
  padding-inline-end: 20px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.pvm-size {
    min-width: 40px;
    min-height: 40px;
}

.pvm-pp-img-select {
  flex-direction: column;
}

@media screen and (max-width: 768px) {
  .pvm-pp-img-select {
    flex-direction: row;
    margin-bottom: 15px;
  }

  .pvm-pp-img-select img {
    width: 33px;
    height: 33px;
    object-fit: cover;
  }
}