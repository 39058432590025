.category-img {
    width: 250px;
    height: 300px;
    object-fit: cover;
    transition: 0.5s;
}

.category-img:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    cursor: pointer;
}